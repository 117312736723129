<template>
  <div class="dialog-container">
    <el-dialog
      title="智能开关数"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <el-row type="flex" justify="center" align="center">
        <div class="table-container">
          <el-table
            :data="itoDetail"
            style="width: 100%"
            border
            @selection-change="handleSelectionChange">
            <el-table-column
              prop="deviceName"
              align="center"
              label="设备名称">
            </el-table-column>
            <el-table-column
              prop="deviceUnit"
              align="center"
              label="设备型号">
            </el-table-column>
            <el-table-column
              align="center"
              prop="location"
              label="安装位置">
            </el-table-column>
            <el-table-column
              align="center"
              prop="online"
              label="在线状态">
              <template slot-scope="scope">
                <div :class="scope.row.online === '1' ? 'onLine': 'offLine'">{{ scope.row.online === '1' ? '在线' : '离线' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-row>
      <div class="page-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="closeDialog">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      }, // 实例化一个表单的变量
      rules: '', // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      data: {},
      itoDetail: [],
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', 'venueId']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(id) {
      this.$http.get(apis.getIotDeviceDetail, {
        params: {
          venueId: this.venueId,
          parentId: id,
        },
      }).then((res) => {
        if (res.data.code === 0) {
          this.itoDetail = res.data.data
          this.form.total = res.data.data.length
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../../../assets/styles/mixin.scoped.less";
.table-container {
  margin-top: 20px;
  width: 100%;
}
.cusomer-head-wrapper {
  display: flex;
  align-items: center;
  .head-item {
    text-align: center;
    flex: 1;
    font-size: 16px;
    font-weight: 400;
  }
  .date {
    flex: 2;
  }
}
.customer-item {
  display: flex;
  align-items: center;
  border: 1px solid #EBEEF5;
  border-bottom: 0;
    padding: 16px 20px;
  .customer-item-detial {
    flex: 1;
    text-align: center;
  }
}
.title{
  border: 1px solid #DCDFE6;
  padding: 16px;
  border-radius: 10px;
  div{
    display: inline-block;
    margin-right: 10px;
    width: 18%;
    /* text-align: center; */
  }
}
.table-title{
  border: 1px solid #DCDFE6;
  padding: 16px 20px;
  div{
    display: inline-block;
    margin-right: 10px;
    width: 15%;
    text-align: center;
  }
}
</style>
<style lang="less">
@import "../../../../../../assets/styles/mixin.less";
</style>

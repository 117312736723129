<template>
  <div class="convenience">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="0px">
        <el-row type="flex" justify="space-between">
          <el-col :span="12" class="head-left-wrapper" v-if="false">
            <div class="input-wrapper">
              <el-input @keyup.enter.native="searchData" placeholder="请输入关键字" v-model="form.searchValue"></el-input>
              <img @click="searchData" class="input-image" src="../../../../../assets/images/input_search.png" alt="" srcset="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="btn-wrapper">
              <el-button v-if="false" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
              <el-button @click="changeAddDialog(true)" class="search-button">{{$t('add')}}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        border
      >
        <el-table-column
         	prop="deviceCode"
          min-width="115"
          align="center"
					label="设备ID">
        </el-table-column>
        <el-table-column
          prop="deviceName"
          align="center"
          min-width="168"
					label="设备名称">
        </el-table-column>
        <el-table-column
          prop="deviceUnit"
          align="center"
          min-width="115"
          label="设备型号">
        </el-table-column>
        <el-table-column
          prop="venueName"
          min-width="168"
          align="center"
          label="场馆"
        ></el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          min-width="120"
          label="运动项目"
        >
        </el-table-column>
        <el-table-column
          prop="location"
          min-width="120"
          align="center"
          label="安装地点"
        ></el-table-column>
        <el-table-column
          prop="online"
          align="center"
          min-width="115"
          label="在线状态"
        >
          <template slot-scope="scope">
            <div :class="scope.row.online === '0' ? 'onLine': 'offLine'">{{ scope.row.online === '0' ? '在线' : '离线' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="display"
          min-width="115"
          align="center"
          label="首页是否显示"
        >
          <template slot-scope="scope">
            <span>{{scope.row.display | displayFilter}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="168"
          label="添加时间"
        ></el-table-column>
        <el-table-column label="操作" min-width="168" align="center">
          <template slot-scope="scope">
            <span class="oper-text" v-auth="'fitnessFacilities:facilityManagement:edit'" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</span>
            <span class="line"></span>
            <span class="oper-text" v-if="buttonAuth.includes('system:user:remove')" @click="openDeleteDialog(scope.row.id, 1)">{{$t('delete')}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      :sportsProjectList="sportsProjectList"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
    <!-- 详情弹窗 -->
    <DetailDialog ref="detailDialog" :show="detailDialog.visible" @closeDialog="changeDetailDialog"></DetailDialog>
  </div>
</template>
<script>
import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";
import DetailDialog from './dialog/detailDialog.vue'
import confirmDialog from "@/components/confirmDialog.vue";
import createDeleteMixin from '@/mixins/createDeleteMixin.js'

export default {
  mixins: [mixin, createDeleteMixin(apis.fitnessDeviceManage)],
  components: { addDialog, DetailDialog, confirmDialog },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      sportsProjectList: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      detailDialog: {
        visible: false,
      },
    };
  },
  filters: {
    displayFilter(status) {
      const displayMap = {
        'Y': '是',
        'N': '否',
      }
      return displayMap[status]
    },
  },
  activated() {
    console.log("++++++++venueId:", this.venueId)
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    /**
     * @function 打开智能开关数弹窗
     * @param {Array} id 选中的ID数组
     */
    checkDetail(id) {
      this.detailDialog.visible = true
      console.log("+++++++id:", id)
      this.$refs.detailDialog.getDataQuery(id)
    },
    /**
     * @function 关闭智能开关数弹窗
     */
    changeDetailDialog() {
      this.detailDialog.visible = false
    },
    // 清空按钮
    cleanForm() {
      this.form = new IndexClass('form')
      this.$nextTick(() => {
        this.searchData()
      })
    },
    // 查询
    searchData() {
      const formData = { ...this.form };
      console.log("+++++++++++++formData:", formData)
      this.$http.get(apis.getIotDeviceListByType, {
        params: {
          deviceType: '8',
          venueId: this.venueId,
          page: this.form.pageNum,
          pageSize: this.form.pageSize,
        },
      }).then((res) => {
        if (res.data.code === 0) {
          console.log("++++++++++++++++++list:", res.data)
          this.tableData = res.data.data.iotDeviceInfoList;
          this.sportsProjectList = res.data.data.sportsProjectList;
          this.form.total = res.data.data.totalCount
        }
      });
    },
    // 新增修改
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
        console.log(this.addDialog.visible);
      });
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type
      this.confirmDialog.data.ids = id
      this.changeConfirmDialog(true, type)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      this.$http.delete(`${apis.delIotDevice}?id=${this.confirmDialog.data.ids}`).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!")
          this.searchEvent()
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.search-container {
  margin: 0;
  height: 50px;
  overflow: hidden;
  .head-left-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: right;
    .input-wrapper {
      width: 360px;
      position:relative;
    }
    .input-image {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .el-button {
      height: 36px;
      margin-left: 15px;
    }
  }
}
.convenience {
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}

.allOff-wrapper{
  display: inline-block;
  margin-right: 38px;
  .allAndOff{
    margin-right: 10px;
  }
}
.btn-wrapper {
  display: inline-block;
  margin-right: 32px;
}
.oper-text{
  color: #439BFF;
  font-size: 12px;
  cursor: pointer;
}
.line{
  display: inline-block;
  margin:0 8px;
  width: 1px;
  height: 13px;
  background: #D8D8D8;
}
.onLine{
  color: #6DD400;
}
.offLine{
  color: #E02020;
}
.textColor{
  color: #439BFF;
}


</style>

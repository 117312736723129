import Vue from 'vue'
import i18n from '@/i18n';

/*
todo:现在这个完全依赖于组件本身已经包含了el-dialog并且继承了dialogMixin.js

使用方式，将要以弹窗显示的组件作为第一个参数传递进去：
import dialog from "@/unit/dialog.js";
import confirmDialog from "@/components/confirmDialogNew.vue";

dialog(confirmDialog)

options会作为组件的props被传递给组件
 */

/**
 * 可将组件作为方法一样调用
 * @param {import('vue').Component} component
 * @param {object} options - 会被作为弹窗的props
 * @param {function} onSure - 确定的事件，没有参数
 * @param {function} onClose -取消的事件，没有参数
 */
export default function (component, options = {}, onSure = null, onClose = null) {

    if (undefined === options.show) {
        options.show = true
    }
    const mOption = Vue.observable(options)

    const instance = new Vue({
        i18n,
        render(h) {
            return h(component, {
                props: mOption,
                on: {
                    sureDialog() {
                        mOption.show = false;
                        if (onSure) {
                            onSure();
                        }
                    },

                    closeDialog() {
                        // console.log('111')
                        mOption.show = false;
                        if (onClose) {
                            onClose();
                        }
                    },
                },
            })
        },
    })

    instance.$mount();
    document.body.appendChild(instance.$el);
    return instance
}

/**
 * 两步保存的对话框
 * 需要页面上存在ref为form跟form2的表单
 */

export default {
    methods: {
        // 下一步
        nextButton() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.form.status = 'second'
                }
            })
        },

        /**
         * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
         */
        sureEvent() {
            // 这里写业务需求
            if (!this.$refs.form) {
                console.error('未找到ref为form的表单，无法进行校验');
                this.mOnSave();
                return
            }

            this.$refs.form.validate((valid) => {
                if (!valid) {
                    this.form.status = "first";
                    return
                }

                if (!this.$refs.form2) {
                    console.error('未找到ref为form2的表单，无法进行校验');
                    this.mOnSave();
                    return
                }

                this.$refs.form2.validate((valid2) => {
                    if (!valid2) {
                        this.form.status = "second";
                    }

                    this.mOnSave();
                });
            });
            // console.log(`我执行了点确定的业务需求`);
        },

        /**
         * @private
         */
        mOnSave() {
            if (this.onSave) {
                this.onSave();
            } else {
                console.error('未找到 onSave 方法，请将保存的业务逻辑写在 onSave 方法中，或不要混入 twoStepDialogMixin')
            }
        },


    },
}

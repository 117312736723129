import dialog from '@/unit/dialog.js'
import confirmDialog from '@/components/confirmDialogNew.vue'
import { isArray } from 'lodash'

/*
创建删除的mixin，url参数是删除请求的地址

使用方法：
import createDeleteMixin from '@/mixins/createDeleteMixin.js'

export default {
    mixins: [createDeleteMixin(apis.convenienceManage)],
}

然后要删除时直接调用 this.deleteItem 就自动完成删除确认，发送请求，请求完成后调用searchData的逻辑

*/

/**
 * 创建删除的mixin
 * @param {string} url
 */
export default function (url) {
    return {
        methods: {
            deleteItem(ids) {
                let mIds

                if (ids === undefined || ids instanceof Event) {
                    mIds = this.selectedArr.map(item => item.id)
                } else
                if (isArray(ids)) {
                    mIds = ids
                } else {
                    mIds = [ids]
                }

                dialog(confirmDialog, {
                    text: this.$t('confirmDialog_type1'),
                }, () => {
                    this.$http
                    .delete(`${url}?ids=${mIds.join(',')}`)
                    .then((res) => {
                      if (res.data.code === 0) {
                        // this.$refs.confirmDialog.closeDialog();
                        this.$message.success("删除成功!");
                        if (this.searchData) {
                            this.searchData();
                        }

                      }
                    });
                })
            },
        },
    }
}
